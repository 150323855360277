@use "../../../node_modules/animate.css/source/_base.css";
@use "../../../node_modules/animate.css/source/_vars.css";
// import only keyframes to be used
@use "../../../node_modules/animate.css/source/fading_entrances/fadeIn.css";
@use "../../../node_modules/animate.css/source/fading_exits/fadeOut.css";
@use "../../../node_modules/animate.css/source/fading_entrances/fadeInUp";
@use "../../../node_modules/animate.css/source/fading_entrances/fadeInDown";
@use "../../../node_modules/animate.css/source/fading_entrances/fadeInRight";
@use "../../../node_modules/animate.css/source/fading_entrances/fadeInLeft";
@use "../../../node_modules/animate.css/source/zooming_entrances/zoomIn.css";

@use "../settings/colors" as *;

/* 
    keyframes
*/

@keyframes cfa-slideInLTRStacked {
  0% {
    opacity: 0;
    transform: translate3d(calc(var(--offset) * -1), 0, 0);
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes cfa-slideInRTLStacked {
  0% {
    opacity: 0;
    transform: translate3d(var(--offset), 0, 0);
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes cfa-breathing {
  0% {
    opacity: 0.6;
    visibility: visible;
  }

  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.4;
  }
}

@keyframes cfa-scaleOnHover {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.2);
  }
}

@keyframes cfa-fadeInLeftSubtle {
  from {
    opacity: 0;
    transform: translate3d(-5%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes cfa-fadeInRightSubtle {
  from {
    opacity: 0;
    transform: translate3d(5%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

/* The typewriter cursor effect */
@keyframes cfa-blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: $c-primary;
    border-right-width: 5px;
  }
}

.cfa-blink-caret {
  border-color: transparent;
  border-right-width: 0px;
  animation: cfa-blink-caret 0.75s infinite;
}

.cfa-fadeInLeftSubtle {
  --offset: 25%;
  animation: cfa-fadeInLeftSubtle 1.5s linear;
  animation-fill-mode: both;
  animation-delay: 250ms;
}

.cfa-fadeInRightSubtle {
  --offset: 25%;
  animation: cfa-fadeInRightSubtle 1.5s linear;
  animation-fill-mode: both;
  animation-delay: 250ms;
}

@keyframes cfa-slideInHeight {
  from {
    height: 0%;
  }

  to {
    height: 100%;
  }
}

@keyframes cfa-slideOutHeight {
  from {
    height: 100%;
  }

  to {
    height: 0%;
  }
}

.cfa-slideInHeight {
  animation: cfa-slideInHeight 1.25s linear;
  animation-delay: 250ms;
}

.cfa-slideOutHeight {
  animation: cfa-slideOutHeight 1.25s linear;
  animation-delay: 250ms;
}

.fadeIn {
  animation-delay: 0.25s;
}

/* 
  Animation classes
*/

.cfa-scaleOnHover {
  transition: transform 300ms cubic-bezier(0, 0.73, 0.48, 1);
  &:hover {
    transform: scale(1.18);
  }
}

@keyframes cfa-wordSlideUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.cfa-wordSlideUp {
  --animation-delay: 250ms;
  animation: cfa-wordSlideUp 250ms linear both;
  animation-timing-function: cubic-bezier(0.33, 0, 0.15, 1);
  margin-right: 18px;
}

.cfa-slideInLTRStacked {
  animation: cfa-slideInLTRStacked 1.25s linear;
  animation-fill-mode: both;
}

.cfa-slideInRTLStacked {
  animation: cfa-slideInRTLStacked 1.25s linear;
  animation-fill-mode: both;
}

.cfa-breathing {
  animation: cfa-breathing 2s linear infinite;
}

/* 
    utils
*/

.animated {
  visibility: hidden;
}

.on-animation {
  visibility: visible;
}

@keyframes fadeUpWords {
  0% {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animated__fade-up-words {
  animation: 250ms linear 2s 1 both fadeUpWords;
  animation-timing-function: cubic-bezier(0.33, 0, 0.15, 1);
}

@keyframes cfa-width-in {
  from {
    background: $c-primary;
    width: 0%;
  }

  to {
    width: 100%;
    background: $c-primary;
  }
}

@keyframes cfa-width-out {
  to {
    width: 0;
    left: 100%;
  }
}

.cta-words-up {
  --animation-delay: 250ms;

  span {
    display: inline-block;
    margin-right: 10px;
  }
  span:nth-child(1) {
    animation-delay: calc(var(--animation-delay) * 1);
  }

  span:nth-child(2) {
    animation-delay: calc(var(--animation-delay) * 2);
  }
  span:nth-child(3) {
    animation-delay: calc(var(--animation-delay) * 3);
  }
  span:nth-child(4) {
    animation-delay: calc(var(--animation-delay) * 4);
  }
  span:nth-child(5) {
    animation-delay: calc(var(--animation-delay) * 5);
  }
  span:nth-child(6) {
    animation-delay: calc(var(--animation-delay) * 6);
  }
  span:nth-child(7) {
    animation-delay: calc(var(--animation-delay) * 7);
  }
  span:nth-child(8) {
    animation-delay: calc(var(--animation-delay) * 8);
  }
  span:nth-child(8) {
    animation-delay: calc(var(--animation-delay) * 9);
  }
  span:nth-child(9) {
    animation-delay: calc(var(--animation-delay) * 2);
  }
}

@keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
}

.cfa-pulse {
  box-shadow: 0 0 0 0 rgba($c-primary, 0.7);
  animation: pulse 1.5s infinite cubic-bezier(0.66, 0, 0, 1);
}
