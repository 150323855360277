@use "../settings/breakpoints" as *;
@use "../settings/colors" as *;
@use "../settings/mixins" as *;
@use '../components/hamburger-icon';

.cf-header {
  z-index: 100;
  position: relative;
  $_mb: 1130px;
  --mobile-breakpoint: #{$_mb};
  --top-container-height: 80px;

  .cf-brand a {
    text-decoration: none;
  }

  .cf-nav {
    @include box-shadow(0px, -7px, 11px, 5px, rgba($c-black, 0.1));
    background-color: rgba($c-bg, 0.4);
    height: 76px;

    .animated {
      animation-delay: 250ms;
    }
  }

  .cf-nav .container > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav {
    ul {
      display: flex;
      list-style: none;
      justify-content: space-around;
      padding: 0;
      margin: 0;
    }

    .cf-nav__item {
      cursor: pointer;
      padding: 1rem;

      &.active {
        font-weight: bold;
      }

      a {
        text-decoration: none;
        color: initial;
        position: relative;
        width: max-content;

        &:hover {
          &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 3px;
            height: 2px;
            background: transparent;
            animation: cfa-width-in 250ms ease-in forwards,
              cfa-width-out 300ms ease-out 750ms forwards;
          }
        }
      }
    }

    .cf-nav__item.active {
      font-weight: bold;
    }
  }

  #mobile-nav-toggle {
    display: none;
  }

  @include breakpoint($size: $_mb) {
    .nav {
      display: none;
      position: absolute;
      top: 80px;
      left: 0;
      width: 100%;
      background: linear-gradient(140deg, #ffffff 40%, #00ffb7 100%);
      overflow-y: auto;

      padding: 40px 0;
      ul {
        flex-direction: column;
        align-items: center;
        text-align: center;

        li a {
          font-size: 2rem;
          line-height: 2.8rem;
          width: 100% !important;
          display: block;
        }

        li {
          text-decoration: underline;
          margin: 20px;
          padding: 10px;
        }
      }
    }

    #mobile-nav-toggle {
      display: block;
    }

    @keyframes nav-animation-enter {
      from {
        height: 0;
        opacity: 1;
      }

      to {
        visibility: visible;
        height: calc(100vh - 80px);
      }
    }

    @keyframes nav-animation-exit {
      from {
        visibility: visible;
        height: calc(100vh - 80px);
      }

      to {
        visibility: hidden;
        height: 0;
        opacity: 0;
      }
    }

    .nav-is-visible {
      display: block;
      visibility: hidden;
      animation: nav-animation-enter 1.5s cubic-bezier(0.075, 0.82, 0.165, 1)
        forwards;
    }

    .nav-not-visible {
      animation: nav-animation-exit 1s cubic-bezier(0.075, 0.82, 0.165, 1)
        forwards;
    }
  }
}
