@use "../settings" as *;

.cf-hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  --animation-delay: 200ms;
  $max-height-container: 1080px;
  max-width: 1920px;
  margin: 0 auto;

  @include breakpoint(1200px) {
    flex-direction: column;

    .cf-hero-content__img {
      max-width: $bp-sm;
      margin: 0 auto;
      margin-top: 50px;
    }
  }

  .cf-hero-content__img {
    width: 600px;
    height: 600px;
    margin: 0 auto;

    @include breakpoint(1550px) {
      display: flex;
      width: 400px;
    }

    @include breakpoint(1350px) {
      width: 250px;
    }

    @include breakpoint(1200px) {
      width: 400px;
    }

    @include breakpoint(600px) {
      width: 100%;
      height: auto;
    }
  }

  .cf-hero-bg {
    &:before,
    &:after {
      position: absolute;
      content: "";
      top: -86px;
      z-index: -1;
      background-position: center;
      background-repeat: no-repeat;
      width: calc(590.58px + 300px);
      height: calc(683.52px + 300px);
    }

    &:before {
      left: -125px;
      background-image: url("/assets/images/lander_bg_1.svg");
    }

    &:after {
      right: 0;
      background-image: url("/assets/images/lander_bg_2-alt.svg");
    }

    @include breakpoint(1921px, "min-width") {
      &:after {
        width: calc(590.58px + 600px);
      }
    }

    @include breakpoint(1190px) {
      &:before {
        display: none;
      }

      &:after {
        width: 100%;
        background-position: center;
      }
    }
  }

  @include breakpoint($max-height-container, "max-height") {
    min-height: calc(100vh - 76px);
  }

  .cf-hero__container {
    max-width: $bp-xxl;
    margin: 0 auto;
    padding: 2rem;
    padding-top: 3rem;
  }

  .cf-hero-content {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 60px;
    align-items: center;

    .cf-hero-content__text {
      @include breakpoint(1200px, "min-width") {
        width: 835px;
      }

      h1 {
        &.cf-hero-content__text-heading--animation {
          span {
            --animation-delay: 250ms;
            animation: cfa-wordSlideUp 250ms linear both;
            animation-timing-function: cubic-bezier(0.33, 0, 0.15, 1);
            display: inline-block;
            padding-right: 15px;

            &:nth-child(1) {
              animation-delay: var(--animation-delay);
            }

            &:nth-child(2) {
              animation-delay: calc(var(--animation-delay) * 2);
            }

            &:nth-child(3) {
              animation-delay: calc(var(--animation-delay) * 3);
            }

            &:nth-child(4) {
              animation-delay: calc(var(--animation-delay) * 4);
            }

            &:nth-child(5) {
              animation-delay: calc(var(--animation-delay) * 5);
            }

            &:nth-child(6) {
              animation-delay: calc(var(--animation-delay) * 6);
            }

            &:nth-child(7) {
              animation-delay: calc(var(--animation-delay) * 7);
            }
          }
        }
      }

      @keyframes fade {
        to {
          opacity: 1;
        }
      }

      p.cf-hero-content__text-animation {
        animation: fade 2.5s linear forwards;
        animation-delay: 250ms;
        margin-top: 3rem;
        opacity: 0;
      }
    }

    img {
      width: 360px;
    }

    @include breakpoint(1160px) {
      grid-template-columns: 100%;
      grid-row-gap: 60px;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    @include breakpoint(1200px) {
      grid-template-columns: 100%;
      grid-column-gap: 0;
      grid-row-gap: 3rem;
      padding-bottom: 4rem;
      padding-top: 5rem;

      .cf-hero-content__text {
        text-align: center;
      }

      p {
        max-width: 100%;
      }
    }
  }

  .cf-hero-scroll-more {
    cursor: pointer;
    margin-top: 2rem;
    margin-bottom: 4rem;
    width: 60px;
    position: relative;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }

  @include breakpoint($bp-xxl, "max-width") {
    .cf-hero-scroll-more {
      margin-top: 7rem;
    }
  }

  @include breakpoint($bp-md) {
    .cf-hero__container {
      padding-left: 0;
      padding-right: 0;
    }

    .cf-hero-content > .cf-hero-content__text {
      width: 95%;
      margin: 0 auto;
    }
  }
}

.cf-highlight {
  text-align: center;
  line-height: 3rem;
  position: relative;
  text-align: center;

  h4 {
    margin-bottom: 5rem;
    display: inline-block;
  }

  .cf-highlight-text {
    font-weight: bold;
    word-wrap: break-word;

    > div.cf-highlight-text--animation {
      span {
        --animation-delay: 250ms;
        animation: cfa-wordSlideUp 250ms linear both;
        animation-timing-function: cubic-bezier(0.33, 0, 0.15, 1);
        display: inline-block;

        &:nth-child(1) {
          animation-delay: var(--animation-delay);
        }

        &:nth-child(2) {
          animation-delay: calc(var(--animation-delay) * 2);
        }

        &:nth-child(3) {
          animation-delay: calc(var(--animation-delay) * 3);
        }

        &:nth-child(4) {
          animation-delay: calc(var(--animation-delay) * 4);
        }

        &:nth-child(5) {
          animation-delay: calc(var(--animation-delay) * 5);
        }
      }
    }

    h4 {
      margin: 0;
    }

    span {
      display: inline-block;
    }

    span:nth-child(2) {
      animation-delay: calc(var(--animation-delay) * 2);
    }

    span:nth-child(3) {
      animation-delay: calc(var(--animation-delay) * 3);
    }

    span:nth-child(4) {
      animation-delay: calc(var(--animation-delay) * 4);
    }

    span:nth-child(5) {
      animation-delay: calc(var(--animation-delay) * 5);
    }
  }
}

@mixin cf-highlight-card-icon-inverted {
  .cf-highlight-card-icon img {
    filter: brightness(0) invert(1);
  }
}

.cf-highlight-cards {
  max-width: $bp-xl;
  margin: 0 auto;

  @include breakpoint($bp-xl) {
    flex-direction: column;
  }

  @include pseudoElementWaterdrop(
    $bottom: -11rem,
    $width: 100%,
    $height: 200px,
    $left: 0,
    $opacity: 0.4
  );

  @keyframes cfa-highlight-card-animation {
    from {
      visibility: visible;
      opacity: 0;
    }

    85% {
      opacity: 1;
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  .cfa-highlight-card-animation {
    @include box-shadow(5px, 5px, 25px, 0px, rgba(0, 0, 0, 0.05));
    .content {
      animation: cfa-highlight-card-animation 1s
        cubic-bezier(0.075, 0.82, 0.165, 1) forwards
        calc(var(--delay) * var(--idx));
    }
  }

  .cf-highlight-card {
    width: 360px;
    height: 445px;
    text-align: left;
    margin-right: 30px;
    margin-top: 40px;
    overflow: hidden;
    display: inline-flex;
    --shadow-delay: 0.75s;
    --delay: 500ms;

    .content {
      --border-size: 5px;
      width: 100%;
      height: 100%;
      padding: 20px;
      position: relative;

      &::before {
        position: absolute;
        background: $c-primary;
        transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1);
        content: "";
        height: 100%;
        width: 100%;
        transform: translate3d(calc((100% - 5px) * -1), 0, 0);
        left: 0;
        top: 0;
        z-index: -1;
      }
    }

    @include breakpoint($bp-xl) {
      height: 480px;
    }

    @include breakpoint(876px) {
      margin-right: 0;
    }

    @include breakpoint($bp-xs) {
      width: 95%;
    }

    p {
      margin: 10px 0;
    }

    span {
      letter-spacing: 0px;
      text-align: center;
      display: inline-block;
    }

    span.title {
      text-transform: uppercase;
      text-align: left;
      font-weight: 600;
    }

    .cf-highlight-card-icon {
      text-align: left;
      height: 75px;
      margin-bottom: 10px;
    }

    &:hover {
      @include box-shadow;
      .content {
        &::before {
          background-color: $c-primary;
          transform: translate3d(0, 0, 0);
        }
      }
      span {
        color: $c-bg;
      }
    }

    &.active {
      background-color: $c-primary;
      color: $c-bg;
      span {
        color: $c-bg;
      }
      transition: background-color 1.25s linear, color linear;
      @include cf-highlight-card-icon-inverted;
      box-shadow: unset;
    }

    &:not(.active) {
      &:hover {
        color: $c-bg;
        @include cf-highlight-card-icon-inverted;
      }
    }
  }

  &:hover > .cf-highlight-card.active:not(:hover) {
    background: $c-bg;
    color: $c-black;
    span {
      color: $c-black;
    }

    @include cf-highlight-card-icon-inverted;
    @include box-shadow(5px, 5px, 25px, 0px, rgba(0, 0, 0, 0.05));
  }
}

.section.cf-onestop-section {
  text-align: center;
  padding: 50px;
  margin-top: 15rem;
  background: lighten($color: $c-primary-light, $amount: 11%);
  @include box-shadow(0px, 5px, 20px, 15px, rgba($c-green, 0.1));

  h1 {
    margin-bottom: 6px;
  }

  p {
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 10px;

    @include breakpoint($bp-lg) {
      max-width: 100%;
    }
  }

  h3 {
    margin-bottom: -0.75rem;
  }

  .cf-onestop-features {
    display: grid;
    grid-template-columns: repeat(2, 375px);
    justify-content: center;
    grid-column-gap: 30rem;
    grid-row-gap: 10rem;
    position: relative;

    .bg-img {
      position: absolute;
      position: absolute;
      width: 360px;
      max-width: 500px;

      top: 0;
      left: 50%;
      transform: translate3d(-50%, 50%, 0);
      height: auto;

      img {
        width: 100%;
        height: 100%;
      }

      @include breakpoint(360px) {
        width: 100%;
      }
    }

    @include breakpoint($bp-xl) {
      grid-template-columns: 100%;
      grid-row-gap: 5rem;
      padding-top: 30rem;

      .bg-img {
        transform: translate3d(-50%, 25%, 0);
      }
    }
  }

  .cf-onestop-feature {
    min-height: 130px;
    margin: 3rem 0;

    .title {
      display: block;
      color: $c-primary;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    .text-xl {
      font-size: 20px;
    }

    animation-delay: 250ms;
    animation-duration: 1.75s;
  }
}

.cf-manage-network {
  text-align: center;

  h1 {
    margin-bottom: 6px;
  }

  h3 {
    margin-bottom: 1.5rem;
  }

  p {
    margin-top: 0;
    margin-bottom: 3rem;
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 10px;

    @include breakpoint($bp-lg) {
      max-width: 100%;
    }
  }

  .cf-manage-network-features {
    display: grid;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    grid-template-columns: repeat(auto-fit, 288px);
    grid-column-gap: 50px;
    grid-row-gap: 30px;
    --item-width: 288px;
    --item-height: 310px;
    --item-padding: 8px;
    --item-full-width: calc(var(--item-width) + var(--item-padding));
    --item-idx: 0;
    --delay: 250ms;
    min-height: var(--card-height);

    &.animation-slideInLTRStacked {
      animation-delay: var(--delay);
    }

    .cf-manage-network-feature {
      &:hover {
        @include pseudoElementWaterdrop(
          $center: true,
          $width: 300px,
          $height: 300px
        );
      }

      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid darken($c-primary-light, 10%);
        border-radius: 8px;
        font-weight: 600;
        width: var(--item-width);
        height: var(--item-height);
        padding: var(--item-padding);
        background: rgba($c-bg, 0.8);
        position: relative;

        &:nth-child(1) {
          animation-delay: var(--delay);
          z-index: 1;
        }

        &:nth-child(2) {
          --item-idx: 1;
          animation-delay: var(--delay);
          z-index: 2;
        }

        &:nth-child(3) {
          --item-idx: 2;
          animation-delay: var(--delay);
          z-index: 3;
        }

        &:nth-child(4) {
          --item-idx: 3;
          animation-delay: var(--delay);
          z-index: 4;
        }
      }
    }

    @include breakpoint(288px) {
      grid-template-columns: 100%;

      .cf-manage-network-feature {
        --item-width: 95%;

        > div {
          margin: 0 auto;
        }
      }
    }

    @include breakpoint($bp-xl) {
      .cf-manage-network-feature {
        position: relative;
        left: 0 !important;
      }
    }
  }
}

.cf-floating-waterdrop {
  max-width: 1920px;
  margin: 0 auto;
  @include pseudoElementWaterdrop($width: 320px, $height: 237px);
  @include breakpoint(1919px) {
    max-width: 95%;
  }
}

.cf-floating-waterdrop-1 {
  @extend .cf-floating-waterdrop;

  &:before {
    top: -8rem;
    right: 0;
  }
}

.cf-floating-waterdrop-2 {
  @extend .cf-floating-waterdrop;

  &:before {
    top: -8rem;
    left: 0;
  }
}

.cf-fund-property {
  max-width: $bp-xl;
  margin: 0 auto;
  position: relative;
  padding: 0 25px;

  h3 {
    margin-bottom: 6rem;
  }

  p {
    margin-bottom: 3rem;
  }
}

.cf-fund-property-features {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: 3rem 0;
    display: flex;
    align-items: center;
  }
}

@mixin cfa-checkmark($name, $width) {
  @keyframes #{$name} {
    0% {
      width: 0;
    }
    100% {
      width: $width;
    }
  }
}

@keyframes cfa-box-animation-text {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
    visibility: visible;
  }

  to {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }
}

.cf-fund-property-docs-container {
  display: grid;
  grid-template-columns: 360px auto;
  justify-content: center;
  grid-column-gap: 230px;
  position: relative;
  align-items: center;
  @include breakpoint($bp-xl) {
    grid-column-gap: 50px;
  }

  @include breakpoint($bp-lg) {
    align-items: center;
    grid-template-columns: 100%;
    grid-row-gap: 40px;

    .cf-fund-property-docs-img {
      order: 1;
    }
  }

  .cf-fund-property-docs-img {
    @include pseudoElementWaterdrop(
      $top: 9rem,
      $left: 4rem,
      $height: 150px,
      $width: 200px
    );

    img {
      width: 360px;
      height: auto;
    }

    @include breakpoint(360px) {
      img {
        width: 100%;
      }
    }

    @include breakpoint($bp-lg) {
      left: 50%;
      transform: translate3d(-50%, 0, 0);
    }

    @include breakpoint($bp-lg, "min-width") {
      &:before {
        top: 11rem;
        left: 6rem;
        height: 200px;
        width: 250px;
      }
    }
    @include breakpoint($bp-xl, "min-width") {
      img {
        width: 500px;
        height: auto;
      }
    }
  }

  .cf-fund-property-docs-boxes-waterdrop {
    position: relative;
    max-width: 1920px;
    margin: 0 auto;

    @include breakpoint(1919px) {
      max-width: 100%;
    }

    @include pseudoElementWaterdrop(
      $dir: "after",
      $width: 330px,
      $height: 478px,
      $right: -17rem,
      $top: 8rem
    );
  }

  .cf-fund-property-docs-boxes {
    position: relative;
    display: flex;
    flex-direction: column;
    top: -30px;
  }

  $text-duration: 0.5s;
  $check-left-delay: 0.8s;
  $check-right-delay: 1.1s;

  @function checkDelay($t, $delay: 0) {
    @if $t == "left" {
      @return $check-left-delay + $delay;
    } @else {
      @return $check-right-delay + $delay;
    }
  }

  @function withCheckDelay($delay: 0) {
    @return ($check-left-delay + $check-right-delay) + $delay;
  }

  $animation-delay: 0.5s;

  &.cfa-fund-property-animation {
    .cf-fund-property-docs-img {
      animation: fadeIn 2s ease-in-out;
    }

    .cf-fund-property-docs-boxes {
      p {
        display: flex;
        align-items: center;
        position: relative;

        .text {
          position: relative;
          top: 8px;
        }

        &:nth-child(1) {
          .text {
            animation-delay: withCheckDelay();
          }

          .check {
            &::before {
              animation-delay: checkDelay("left");
            }

            &::after {
              animation-delay: checkDelay("right");
            }
          }
        }

        &:nth-child(2) {
          .text {
            animation-delay: withCheckDelay($animation-delay * 2);
          }

          .check {
            &::before {
              animation-delay: checkDelay("left", $animation-delay * 2);
            }

            &::after {
              animation-delay: checkDelay("right", $animation-delay * 2);
            }
          }
        }

        &:nth-child(3) {
          .text {
            animation-delay: withCheckDelay($animation-delay * 4);
          }

          .check {
            &::before {
              animation-delay: checkDelay("left", $animation-delay * 4);
            }

            &::after {
              animation-delay: checkDelay("right", $animation-delay * 4);
            }
          }
        }

        &:nth-child(4) {
          .text {
            animation-delay: withCheckDelay($animation-delay * 6);
          }

          .check {
            &::before {
              animation-delay: checkDelay("left", $animation-delay * 6);
            }

            &::after {
              animation-delay: checkDelay("right", $animation-delay * 6);
            }
          }
        }
      }

      .check {
        $checkmark-width-left: 15px;
        $checkmark-width-right: 30px;
        $checkmark-position-top: 32px;
        $checkmark-position-left: 21px;
        $animation-speed: 0.3s;
        margin-right: 60px;
        /*  
         border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center; */

        &::before,
        &::after {
          content: "";
          position: absolute;
          width: 0;
          height: 5px;
          transform-origin: left;
          background-color: $c-primary;
          animation-duration: $animation-speed;
          animation-timing-function: ease-in-out;
          animation-fill-mode: forwards;
        }

        &::before {
          /*  top: $checkmark-position-top;
          left: $checkmark-position-left; */
          top: 20px;
          left: 18px;
          transform: rotate(45deg);
          animation-name: cfa-checkmark-left;
          will-change: width;
        }

        &::after {
          /*  top: $checkmark-position-top + 10px;
          left: $checkmark-position-left + 8px; */
          top: 30px;
          left: 26px;
          transform: rotate(-45deg);
          animation-name: cfa-checkmark-right;
          will-change: width;
        }

        @include cfa-checkmark(cfa-checkmark-left, $checkmark-width-left);
        @include cfa-checkmark(cfa-checkmark-right, $checkmark-width-right);
      }

      .text {
        animation: cfa-box-animation-text $text-duration linear forwards;
        visibility: hidden;
        word-break: break-word;
      }
    }
  }
}
