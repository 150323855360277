@use "../settings/colors" as *;
@use "../settings/breakpoints" as *;
@use "../settings/mixins" as *;

.btn {
  max-height: 63px;
  overflow: visible;
  border-radius: 32px;
  padding: 10px 50px;
  color: $c-bg;
  background-color: $c-primary;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  border: 0;
  transition: all 300ms ease-in-out;
  @include useFontSize("text-2xl");

  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    @include box-shadow;
    background-color: darken($c-primary, 10%);
  }

  &:disabled {
    background: #66c7ff;
    cursor: not-allowed;
  }
}

.cf-cta {
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    @include useFontSize("text-2xl");
  }

  @include breakpoint($bp-xs) {
    .btn {
      width: 100%;
      padding: 10px 0px;
    }
  }

  .alt-btns {
    display: flex;

    a {
      margin: 0 20px;
    }

    @include breakpoint($bp-md) {
      flex-direction: column;
      align-items: center;

      a:nth-child(1) {
        margin-bottom: 30px;
      }

      a {
        margin: 0;
      }
    }
  }
}

.alt-btns {
  display: flex;
  justify-content: space-around;
  .btn:nth-child(1) {
    margin-right: 30px;
  }

  @include breakpoint($bp-md) {
    flex-direction: column;

    .btn:nth-child(1) {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }
}

input {
  &:required {
    &:after {
      position: absolute;
      content: "";
      width: 20px;
      height: 20px;
      background: red;
    }
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
}

.table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;

  td,
  th {
    border: 1px solid darken($c-gray, 10%);
    padding: 8px;
  }

  tr {
    &:nth-child(even) {
      background-color: $c-gray;
    }

    &:hover {
      background-color: lighten($c-gray, 5%);
    }
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: $c-primary;
    color: $c-bg;
  }
}
