@use "../settings/breakpoints" as *;
@use "../settings/colors" as *;
@use "../settings/mixins" as *;

.cf-footer {
  position: relative;

  @include breakpoint(1919px) {
    max-width: 100%;
  }

  &:after {
    position: absolute;
    content: "";
    top: -250px;
    z-index: -1;
    right: 0;
    background-image: url("/assets/images/footer_bg.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 870px;
    height: 800px;
  }

  .cf-footer__container {
    @include box-shadow(1px, 5px, 6px, 5px, rgb(0 0 0 / 15%));
    background-color: rgba($c-bg, 0.25);
    z-index: 1;
    padding: 50px;
    .cf-footer__top {
      display: grid;
      position: relative;
      grid-template-columns: 450px 520px 370px;
      justify-content: center;
      align-items: baseline;
      gap: 40px;
      animation-duration: 0.75s;

      .cf-footer__brand {
        .socials-title {
          margin-top: 3rem;
          font-weight: bold;
          display: block;
        }

        .cf-socials-contact {
          margin-top: 1rem;

          a,
          img {
            margin-left: 0;
            margin-right: 10px;
          }
        }
      }
    }

    .cf-footer__bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 2;
      position: relative;
      max-width: 1400px;
      margin: 0 auto;
      margin-top: 8rem;
      margin-bottom: 3rem;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;

        li {
          margin: 10px 25px;

          a {
            text-decoration: none;
            font-weight: 600;
            color: initial;
            &:hover {
              color: $c-primary;
            }
          }

          &.active a {
            color: $c-primary;
            font-weight: bold;
          }
        }
      }
    }

    .cf-footer__links {
      display: grid;
      grid-template-columns: repeat(2, 260px);
      grid-column-gap: 15px;
    }

    .cf-footer__link-group {
      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          margin: 20px 0;

          a {
            text-decoration: none;
            color: initial;

            &:hover {
              color: $c-primary;
            }
          }

          &.active a {
            color: $c-primary;
            font-weight: bold;
          }
        }
      }
    }

    .cf-footer__newsletter {
      .form-group {
        position: relative;
        width: 336px;
      }

      label {
        font-weight: bold;
      }

      .cf-footer__newsletter-input-container {
        position: relative;
        margin: 20px 0;
        width: 336px;
        padding: 15px;
        border-radius: 30px;
        border: 0;
        outline: none;
        height: 50px;
        background-color: $c-bg;

        display: flex;
        align-items: center;
        justify-content: flex-start;

        input {
          outline: none;
          border: 0;
          background-color: $c-bg;
          width: 85%;
        }

        .cf-footer__newsletter-submit {
          position: absolute;
          top: 3px;
          right: 4px;
          background: lighten(#00a1ff, 20%);
          padding: 10px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          border: 0;
        }
      }

      @include breakpoint($bp-lg) {
        .cf-footer__newsletter-input-container {
          padding: 15px 0;
          width: 100%;
        }
      }
    }

    @include breakpoint(1450px) {
      .cf-footer__top {
        grid-template-columns: 430px 390px 330px;
      }

      .cf-footer__newsletter {
        input {
          border: 1.5px solid black;
          border-radius: 80px;
        }
      }
    }

    @include breakpoint(1300px) {
      .cf-footer__top {
        grid-template-columns: 370px 370px 330px;
        gap: 0;
      }
    }

    @include breakpoint($bp-xl) {
      .cf-footer__bottom {
        flex-direction: column;
      }
    }

    @include breakpoint(800px) {
      .cf-footer__bottom-links {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    @include breakpoint(1115px) {
      .cf-footer__top {
        grid-template-columns: 100%;
        gap: 60px;

        .cf-footer__brand {
          text-align: center;

          @include breakpoint(250px) {
            img {
              width: 100%;
            }
          }
        }

        .cf-footer__links {
          text-align: center;
          justify-content: center;
          align-items: center;
        }

        .cf-footer__newsletter {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          text-align: center;
          flex-direction: column;
        }
      }
    }

    @include breakpoint($bp-sm) {
      padding-left: 10px;
      padding-right: 10px;
      .cf-footer__newsletter {
        display: block !important;
        max-width: 95%;
        margin: 0px auto;

        .form-group {
          width: 100%;
        }

        .cf-footer__newsletter-input-container {
          text-align: center;
        }
      }

      .cf-footer__links {
        grid-template-columns: 100%;
        grid-row-gap: 60px;
      }

      .cf-footer__bottom {
        p {
          text-align: center;
        }
      }
    }
  }
}
