@use "./breakpoints" as *;
@use "./colors" as *;
@use "./fonts" as *;

@mixin useFontSize($fk) {
  font-size: map-get($map: $font-size, $key: $fk);
  line-height: map-get($map: $line-height, $key: $fk);
}

@mixin scrollbar($bg: #f1f1f1, $thumb: $c-primary, $thumb-hover: #6fcaff) {
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $bg;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $thumb;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $thumb-hover;
  }
}

@mixin box-shadow(
  $x: 5px,
  $y: 5px,
  $blur: 28px,
  $spread: 0px,
  $color: rgba(0, 0, 0, 0.1)
) {
  box-shadow: $x $y $blur $spread $color;
}

@mixin pseudoElementWaterdrop(
  $dir: "before",
  $center: false,
  $blur: 40px,
  $opacity: 0.6,
  $border-radius: 100%,
  $width: 543px,
  $height: 378px,
  $bottom: unset,
  $top: unset,
  $left: unset,
  $right: unset
) {
  position: relative;
  &:#{$dir} {
    position: absolute;
    content: "";
    z-index: -1;
    right: $right;
    bottom: $bottom;
    -webkit-font-smoothing: subpixel-antialiased;
    backface-visibility: hidden;

    @if $center {
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
    } @else {
      left: $left;
      top: $top;
    }

    width: $width;
    height: $height;
    background: linear-gradient(237deg, #00a1ff 0%, #00ffb7 100%);
    filter: blur($blur);
    border-radius: 100%;
    opacity: $opacity;
  }

  @include breakpoint($width) {
    &:#{$dir} {
      width: 95% !important;
      margin: 0 auto;
      left: 0;
      right: unset;
    }
  }
}

@mixin breakpoint($size, $dir: "max-width") {
  @if $size == "xxl" {
    @media screen and (#{$dir}: $bp-xxl) {
      @content;
    }
  } @else if $size == "xl" {
    @media screen and (#{$dir}: $bp-xl) {
      @content;
    }
  } @else if $size == "lg" {
    @media screen and (#{$dir}: $bp-lg) {
      @content;
    }
  } @else if $size == "md" {
    @media screen and (#{$dir}: $bp-md) {
      @content;
    }
  } @else if $size == "sm" {
    @media screen and (#{$dir}: $bp-sm) {
      @content;
    }
  } @else {
    @media screen and (#{$dir}: $size) {
      @content;
    }
  }
}

@mixin placeHolder {
  &::-webkit-input-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &::placeholder {
    @content;
  }
}
